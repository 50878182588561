@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700,900');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
  border: 1px solid gray;
}

::-webkit-scrollbar-thumb:hover {
  background: #555
}

.scroller {
  scrollbar-color: white rgba(0,0,0,0);
  scrollbar-width: thin;
}

.user-panel {
  top: 0;
  width: 25%;
  height: 100%;
  position: fixed;
  background-image: linear-gradient(#1A1A1E, #1A1A1E), linear-gradient(to right, #EB46A6, #E20FF5);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
}

:root {
  --color1: rgb(0, 231, 255);
  --color2: rgb(255, 0, 231);
  --back: url(https://cdn2.bulbagarden.net/upload/1/17/Cardback.jpg);
  --charizard1: #fac;
  --charizard2: #ddccaa;
  --charizardfront: url(https://assets.codepen.io/13471/charizard-gx.webp);
  --pika1: #54a29e;
  --pika2: #a79d66;
  --pikafront: url(https://assets.codepen.io/13471/pikachu-gx.webp);
  --eevee1: #efb2fb;
  --eevee2: #acc6f8;
  --eeveefront: url(https://assets.codepen.io/13471/eevee-gx.webp);
  --mewtwo1: #efb2fb;
  --mewtwo2: #acc6f8;
  --mewtwofront: url(https://assets.codepen.io/13471/mewtwo-gx.webp);
  --prize1: #F8E68A;
  --prize2: #685625;
}
  
.card {
  width: 71.5vw;
  height: 71.5vw;
  @media screen and (min-width: 600px) {
      width: clamp(17vw, 61vh, 17vw);
      height: clamp(17vw, 61vh, 17vw);
  }
  position: relative;
  overflow: hidden;
  overflow: hidden;
  z-index: 10;
  touch-action: none;
  border-radius: 10px;
  box-shadow: 
    -5px -5px 5px -5px var(--color1), 
    5px 5px 5px -5px var(--color2), 
    -7px -7px 10px -5px transparent, 
    7px 7px 10px -5px transparent, 
    0 0 5px 0px rgba(255,255,255,0),
    0 10px 10px -2px rgba(0, 0, 0, 0.5);
  
  transition: transform 0.5s ease, box-shadow 0.2s ease;
  will-change: transform, filter;
  background-color: #040712;
  background-image: var(--front);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform-origin: center;
}
  
.card:hover {
  box-shadow: 
    -20px -20px 30px -25px var(--color1), 
    20px 20px 30px -25px var(--color2), 
    -7px -7px 10px -5px var(--color1), 
    7px 7px 10px -5px var(--color2), 
    0 0 13px 4px rgba(255,255,255,0.3),
    0 55px 35px -20px rgba(0, 0, 0, 0.5);
}
  
.card.charizard {
  --color1: var(--charizard1);
  --color2: var(--charizard2);
  --front: var(--charizardfront);
}
.card.pika {
  --color1: var(--pika1);
  --color2: var(--pika2);
  --front: var(--pikafront);
}
.card.mewtwo {
  --color1: var(--mewtwo1);
  --color2: var(--mewtwo2);
  --front: var(--mewtwofront);
}
.card.eevee {
  --color1: #ec9bb6;
  --color2: #ccac6f;
  --color3: #69e4a5;
  --color4: #8ec5d6;
  --color5: #b98cce;
  --front: var(--eeveefront);
}
.card.prize {
  --color1: var(--prize1);
  --color2: var(--prize2);
}

.card:before,
.card:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  opacity: .5;
  mix-blend-mode: color-dodge;
  transition: all .33s ease;
}

.card.rare:before {
  background-position: 50% 50%;
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    var(--color1) 25%,
    transparent 47%,
    transparent 53%,
    var(--color2) 75%,
    transparent 100%
  );
  opacity: .5;
  filter: brightness(.5) contrast(1);
  z-index: 1;
}

.card.mythic:before {
  background-position: 50% 50%;
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    var(--color1) 25%,
    transparent 47%,
    transparent 53%,
    var(--color2) 75%,
    transparent 100%
  );
  opacity: .5;
  filter: brightness(.5) contrast(1);
  z-index: 1;
}

.card.mythic:after {
  opacity: 1;
  background-image: url("https://assets.codepen.io/13471/sparkles.gif"), 
    url(https://assets.codepen.io/13471/holo.png), 
    linear-gradient(125deg, #ff008450 15%, #fca40040 30%, #ffff0030 40%, #00ff8a20 60%, #00cfff40 70%, #cc4cfa50 85%);
  background-position: 50% 50%;
  background-size: 160%;
  background-blend-mode: overlay;
  z-index: 2;
  filter: brightness(1) contrast(1);
  transition: all .33s ease;
  mix-blend-mode: color-dodge;
  opacity: .75;
}

.card.prize:before {
  background-position: 50% 50%;
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    #F8E68A 25%,
    transparent 47%,
    transparent 53%,
    #685625 75%,
    transparent 100%
  );
  opacity: .5;
  filter: brightness(.5) contrast(1);
  z-index: 1;
}

.card.prize:after {
  opacity: 1;
  background-image: url("https://assets.codepen.io/13471/sparkles.gif"), 
    url(https://assets.codepen.io/13471/holo.png), 
    linear-gradient(
    115deg,
    transparent 0%,
    #F8E68A 25%,
    transparent 47%,
    transparent 53%,
    #685625 75%,
    transparent 100%
  );
  background-position: 50% 50%;
  background-size: 160%;
  background-blend-mode: overlay;
  z-index: 2;
  filter: brightness(1) contrast(1);
  transition: all .33s ease;
  mix-blend-mode: color-dodge;
  opacity: .75;
}

.card.active:after,
.card:hover:after {
  filter: brightness(1) contrast(1);;
  opacity: 1;
}

.card.active,
.card:hover {
  animation: none;
  transition: box-shadow 0.1s ease-out;
}

.card.active:before,
.card:hover:before {
  animation: none;
  background-image: linear-gradient(
    110deg,
    transparent 25%,
    var(--color1) 48%,
    var(--color2) 52%,
    transparent 75%
  );
  background-position: 50% 50%;
  background-size: 250% 250%;
  opacity: .88;
  filter: brightness(.66) contrast(1.33);
  transition: none;
}

.card.active:before,
.card:hover:before,
.card.active:after,
.card:hover:after {
  animation: none;
  transition: none;
}

.card.animated {
  transition: none;
  animation: holoCard 12s ease 0s 1;
  &:before { 
    transition: none;
    animation: holoGradient 12s ease 0s 1;
  }
  &:after {
    transition: none;
    animation: holoSparkle 12s ease 0s 1;
  }
}

.shimmer {
  color: grey;
  display:inline-block;
  -webkit-mask:linear-gradient(-60deg,#FFF 30%,#0005,#FFF 70%) right/300% 100%;
  mask:linear-gradient(-60deg,#FFF 30%,#0005,#FFF 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

@keyframes holoSparkle {
  0%, 100% {
    opacity: .75; background-position: 50% 50%; filter: brightness(1.2) contrast(1.25);
  }
  5%, 8% {
    opacity: 1; background-position: 40% 40%; filter: brightness(.8) contrast(1.2);
  }
  13%, 16% {
    opacity: .5; background-position: 50% 50%; filter: brightness(1.2) contrast(.8);
  }
  35%, 38% {
    opacity: 1; background-position: 60% 60%; filter: brightness(1) contrast(1);
  }
  55% {
    opacity: .33; background-position: 45% 45%; filter: brightness(1.2) contrast(1.25);
  }
}

@keyframes holoGradient {
  0%, 100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(.5) contrast(1);
  }
  5%, 9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }
  13%, 17% {
    background-position: 0% 0%;
    opacity: .88;
  }
  35%, 39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }
}

@keyframes holoCard {
  0%, 100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%, 8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%, 16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%, 38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}

.card.eevee:hover {
  box-shadow: 
    0 0 30px -5px white,
    0 0 10px -2px white,
    0 55px 35px -20px rgba(0, 0, 0, 0.5);
}
.card.eevee:hover:before,
.card.eevee.active:before {
  background-image: linear-gradient(
    115deg,
    transparent 20%,
    var(--color1) 36%,
    var(--color2) 43%,
    var(--color3) 50%,
    var(--color4) 57%,
    var(--color5) 64%,
    transparent 80%
  );
}

.demo .card {
  background-image: var(--back);
  font-size: 2vh
}
.demo .card > span {
  position: relative;
  top: 45%;
}

.demo .card:nth-of-type(1),
.demo .card:nth-of-type(2),
.demo .card:nth-of-type(3) {
  width: 20vh;
  height: 20.5vh;
  box-shadow: inset 0 0 0 1px rgba(white,0.4), 0 25px 15px -10px rgba(0, 0, 0, 0.5);
  animation: none;
}

.demo .card:nth-of-type(1),
.demo .card:nth-of-type(2),
.demo .card:nth-of-type(3) {
  &:before, &:after {
    animation: none;
    // opacity: 1;
  } 
}
.demo .card:nth-of-type(1) {
  &:before, &:after { display: none; }
}
.demo .card:nth-of-type(2) {
  background: none;
  &:before { display: none; }
}
.demo .card:nth-of-type(3) {
  background: none;
  &:after { display: none; }
}

.operator {
  display: inline-block;
  vertical-align: middle;
  font-size: 6vh;
}

.demo,
.cards { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  perspective: 2000px;
  position: relative;
  z-index: 1;
  transform: translate3d(0.1px, 0.1px, 0.1px )
}
.demo {
  flex-direction: row;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .cards {
    flex-direction: row;
  }
}

.cards .card {
  &:nth-child(2) {
    &, &:before, &:after {
      animation-delay: 0.25s;
    }
  }
  &:nth-child(3) {
    &, &:before, &:after {
      animation-delay: 0.5s;
    }
  }
  &:nth-child(4) {
    &, &:before, &:after {
      animation-delay: 0.75s;
    }
  }
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(5px); /* Adjust the blur intensity as needed */
  z-index: -1; /* Place it behind the modal content */
}

.triangle {
  width: 0px;
  height:0px;
  border-bottom: 50px solid #000;
  border-left: 50px solid transparent;
}